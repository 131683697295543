<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.6rem;">名称：</span>
					<el-input v-model="searchinput" placeholder="请输入名称" style="width: 2rem;"></el-input>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showbm="true"
						:czwidth="220" @getdata="gettable" @changswitch="changswitch">
						<template slot="btns" slot-scope="{ scope }">
							<el-button @click="showeidt(scope.row)" type="success" size="mini">详情</el-button>
							<el-button @click="pass(scope.row)" type="primary" size="mini">评级</el-button>
						</template>
					</MyTable>
				</div>
			</div>
		</div>
		
		
		<!--审核弹出-->
		<el-dialog title="评级" :visible.sync="showtc" width="40%" :before-close="setsheng">
			<div>
				<el-form ref="ruleForm" class="demo-ruleForm">
					 <!-- <el-form-item label="单位意见" required>
							<el-input
							  type="textarea"
							  :rows="4"
							  placeholder="请输入单位意见"
							  v-model="sheng.unit_opinion">
							</el-input>
					  </el-form-item> -->
					  <el-form-item label="专门委员会意见" required>
					  		<el-input
					  		  type="textarea"
					  		  :rows="4"
					  		  placeholder="请输入专门委员会意见"
					  		  v-model="sheng.spec_comm_opinion">
					  		</el-input>
					  </el-form-item>
					 <el-form-item label="评审意见" class="alicten" required>
					 	<div style="display: flex;align-items: center;">
					 		<el-radio v-model="sheng.evaluate" label="优秀">优秀</el-radio>
					 		<el-radio v-model="sheng.evaluate" label="称职">称职</el-radio>
					 		<el-radio v-model="sheng.evaluate" label="不称职">不称职</el-radio>
					 	</div>
					 </el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="setsheng">取 消</el-button>
				<el-button type="success" @click="queren">确 认</el-button>
			</span>
		</el-dialog>
		
		
		<detail ref="mydetail" :showsh="true" @detailpj="pass"></detail>
	</div>
</template>

<script>
	import detail from './component/detail.vue';
	export default {
		components:{
			detail
		},
		data() {
			return {
				searchinput: '',
				tableHeader: [{
						prop: 'id',
						name: '序',
						width: '50'
					},
					{
						prop: 'realname',
						name: '用户名',
						width: ''
					},
					{
						prop: 'mobile',
						name: '手机号',
						width: ''
					},
					{
						prop: 'gender',
						name: '性别',
						width: ''
					},
					{
						prop: 'apply_status',
						name: '状态',
						width: '',
						type:'status_lztb'
					},
					{
						prop: 'createtime',
						name: '提交时间',
						width: ''
					}
				],
				tableData: [],
				showtc: false,
				qr_url: '',
				imageLogo: require("@/assets/logo.png"),
				sheng:{
					performance_form_id:"",
					unit_opinion:"",
					spec_comm_opinion:"",
					evaluate:'称职',
				}
			}
		},
		mounted() {
			this.gettable()
		},
		methods: {
			setsheng(){
				this.sheng = {
					performance_form_id:"",
					unit_opinion:"",
					spec_comm_opinion:"",
					evaluate:'称职',
				}
				this.showtc = false
			},
			queren(){
				this.$post({
					url: '/api/user_performance_form/review_performance_form',
					params: this.sheng
				}).then((res) => {
					this.$message.success('操作成功')
					this.$refs.mydetail.dialogVisible = false
					this.setsheng()
					this.gettable()
				})
			},
			editerow(row) {
				this.$router.push({
					path: '/system/activity_edit',
					query: {
						id: row.id
					}
				})
			},
			pass(row) {
				this.sheng.performance_form_id = row.id
				this.showtc = true
			},
			bohui(row) {
				this.$prompt('请输入驳回原因', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputPattern: /\S/,
					inputErrorMessage: '请输入驳回理由'
				}).then(({
					value
				}) => {
					this.$post({
						url: '/api/user_resumption/refund',
						params: {
							id: row.id,
							refund_text:value
						}
					}).then((res) => {
						this.$message.success('操作成功')
						this.gettable()
					})
				}).catch(() => {
					
				});
			},
			showeidt(row) {
				this.$refs.mydetail.dialogVisible = true
				row.diaoyan = JSON.parse(row.diaoyan)
				row.form_summarize = JSON.parse(row.form_summarize)
				row.honor_punish_content = JSON.parse(row.honor_punish_content)
				row.meeting_speak = JSON.parse(row.meeting_speak)
				row.other = JSON.parse(row.other)
				row.shicha = JSON.parse(row.shicha)
				this.$refs.mydetail.form = row
			},
			changswitch(row) {
				this.$post({
					url: '/api/room/edit',
					params: row
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '操作成功!'
					})
					this.gettable()
				})
			},
			search() {
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable() {
				this.$post({
					url: '/api/user_performance_form/performance_from_list',
					params: {
						apply_status:"0",
						is_review:'0',
						name: this.searchinput,
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize
					}
				}).then((res) => {
					this.tableData = res.list
					this.$refs.zjlist.total = res.count
				})
			},
			addnew() {
				this.$router.push('/system/activity_add')
			},
			showdate(row) {
				this.$refs.myroomdate.dialogVisible = true
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import 'index.scss';
	::v-deep .alicten{
		display: flex;
		align-items: center;
	}
</style>